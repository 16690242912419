// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---packages-core-src-templates-post-js": () => import("./../../../packages/core/src/templates/post.js" /* webpackChunkName: "component---packages-core-src-templates-post-js" */),
  "component---packages-core-src-templates-posts-js": () => import("./../../../packages/core/src/templates/posts.js" /* webpackChunkName: "component---packages-core-src-templates-posts-js" */),
  "component---packages-shared-src-pages-404-jsx": () => import("./../../../packages/shared/src/pages/404.jsx" /* webpackChunkName: "component---packages-shared-src-pages-404-jsx" */),
  "component---packages-shared-src-pages-authors-jsx": () => import("./../../../packages/shared/src/pages/authors.jsx" /* webpackChunkName: "component---packages-shared-src-pages-authors-jsx" */),
  "component---packages-shared-src-pages-contact-jsx": () => import("./../../../packages/shared/src/pages/contact.jsx" /* webpackChunkName: "component---packages-shared-src-pages-contact-jsx" */),
  "component---packages-theme-personal-src-pages-about-jsx": () => import("./../../../packages/theme-personal/src/pages/about.jsx" /* webpackChunkName: "component---packages-theme-personal-src-pages-about-jsx" */),
  "component---packages-theme-personal-src-pages-articles-jsx": () => import("./../../../packages/theme-personal/src/pages/articles.jsx" /* webpackChunkName: "component---packages-theme-personal-src-pages-articles-jsx" */),
  "component---packages-theme-personal-src-pages-courses-jsx": () => import("./../../../packages/theme-personal/src/pages/courses.jsx" /* webpackChunkName: "component---packages-theme-personal-src-pages-courses-jsx" */),
  "component---packages-theme-personal-src-pages-placements-jsx": () => import("./../../../packages/theme-personal/src/pages/placements.jsx" /* webpackChunkName: "component---packages-theme-personal-src-pages-placements-jsx" */)
}

